<script setup lang="ts"></script>

<template>
  <div class="header border-b border-gray-200">
    <div class="header-wrapper flex items-center justify-between p-4">
      <slot name="title" />
      <div class="cta">
        <slot name="cta" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
