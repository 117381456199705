<script setup lang="ts">
const slots = useSlots();
const props = defineProps({
  isLoading: Boolean,
});
</script>
<template>
  <div class="default-layout flex flex-col w-full h-full">
    <div
      class="flex flex-col items-center justify-center"
      v-if="props.isLoading"
    >
      <BaseLoader v-if="props.isLoading" />
    </div>
    <template v-else>
      <LayoutHeader>
        <template #title>
          <h3 v-if="slots['header-title']">
            <slot name="header-title"></slot>
          </h3>
          <slot name="header-content"></slot>
        </template>
        <template #cta>
          <div class="header-cta flex items-center gap-2">
            <slot name="header-cta"></slot>
          </div>
        </template>
      </LayoutHeader>
      <div class="w-full h-[calc(100%-70px)] bg-gray-50 overflow-scroll">
        <slot />
      </div>
    </template>
  </div>
</template>
